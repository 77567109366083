<template>
  <section class="container-fluid nav-col d-flex justify-content-center fixed-top">
    <div class="container">
      <nav class="d-flex justify-content-between navbar navbar-expand-md navbar-dark ">
        <!-- Brand -->
        <a class="navbar-brand">
          <img src="@/assets/logo.svg" alt="Logo" style="width: 123.826px;height: 40px;flex-shrink: 0;"/>
        </a>
        <!-- Toggler/collapsibe Button -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="scrollToLocationOfPart('part2')">Layer2</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                Products
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item"  @click="scrollToLocationOfPart('Swap')">Swap</a>
                <a class="dropdown-item" @click="scrollToLocationOfPart('Swap')">Metaverse</a>
                <a class="dropdown-item" @click="scrollToLocationOfPart('Swap')">Domain</a>
                <a class="dropdown-item" @click="scrollToLocationOfPart('Swap')">Compute Mining</a>
                <a class="dropdown-item" @click="scrollToLocationOfPart('Swap')">Staking</a>
              </div>
            </li>



            <!--<li class="nav-item">-->
            <!--  <a class="nav-link" @click="scrollToLocationOfPart('Swap')">Swap</a>-->
            <!--</li>-->
            <!--<li class="nav-item">-->
            <!--  <a class="nav-link" @click="scrollToLocationOfPart('Metaverse')">Metaverse</a>-->
            <!--</li>-->
            <!--<li class="nav-item">-->
            <!--  <a class="nav-link" @click="scrollToLocationOfPart('Domain')">Domain</a>-->
            <!--</li>-->
            <!--<li class="nav-item">-->
            <!--  <a class="nav-link" @click="scrollToLocationOfPart('Compute')">Compute Mining</a>-->
            <!--</li>-->
            <!--<li class="nav-item">-->
            <!--  <a class="nav-link" @click="scrollToLocationOfPart('Staking')">Staking</a>-->
            <!--</li>-->
            <li class="nav-item">
              <a class="nav-link" @click="scrollToLocationOfPart('Tokenomics')">Tokenomics</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="scrollToLocationOfPart('Roadmap')">Roadmap</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
  <section class="container-fluid section-1">
    <div class="container">
      <div class="row d-flex justify-content-center flex-column ">
        <div><h1 class="home-h1">From Meme to Mainstream </h1></div>
        <div><h1 class="home-h1"> - Let me do it for you!</h1></div>
        <div class="col-md-12 col-lg-8 col-xl-8" style="margin: auto">
          <p class="home-p">Borzoi is evolving from a playful Solana memecoin into a comprehensive ecosystem featured by
            Layer 2 solution, Metaverse, Borzoi Domain, and a unique mining mechanism.
            Join us in building a versatile and inclusive crypto ecosystem!</p>
        </div>
        <div class="col-md-8 col-lg-4 col-xl-4 home-but">
          <!--<div class="home-but">-->
          <a href="https://www.mexc.com/zh-CN/exchange/BORZOI_USDT?_from=search_spot_trade"
             style="text-decoration: none">
            <div class="home-but-in">Get Started with $BORZOI</div>
          </a>
          <!--</div>-->
        </div>
        <div class="to-down"></div>
      </div>
    </div>
  </section>
  <section class="container-fluid section-2 d-flex justify-content-center align-self-center" ref="part2">
    <div class="container row d-flex align-items-center justify-content-between" id="lay">
      <div class="col-xl-7 col-lg-12 d-flex flex-column mb-5">
        <h1 class="section-2-h1">Borzoi Layer2</h1>
        <h1 class="section-2-h2">Unleashing the Power of Solana VM</h1>
        <div class="section-2-desc">Borzoi Protocol is a cutting-edge Layer 2 solution that harnesses the capabilities
          of the Solana Virtual Machine (SVM), acting as a bridge between Solana and other thriving ecosystems.
        </div>
        <h1 class="section-2-h2" style="margin-top: 16px">Layer 2 Innovation</h1>
        <div class="section-2-desc" style="margin-top: 34px">Borzoi Protocol is developing a versatile scalability
          framework for SVM-based
          rollups. This groundbreaking approach opens up new horizons for blockchain architecture, enabling seamless
          integration of SVM-powered applications on networks such as Ethereum and Cosmos.
        </div>
      </div>
      <div class="col-xl-5 col-lg-12 d-flex justify-content-center">
        <img src="@/assets/images/section-2-new.png" class="section-2-img"/>
      </div>
    </div>
  </section>
  <section class="container-fluid section-3">
    <div class="container section-3-row">Our famous long-snouted mascot, Borzoi, embodies our curiosity and
      determination to explore every corner of the decentralized realm. Through our "Let Me Do It For You" dream fund,
      we support charitable initiatives and help individuals turn their aspirations into reality.
    </div>
  </section>
  <section class="container-fluid section-4">
    <div class="container d-flex flex-column align-items-center">
      <div class="section-4-h2">How Borzoi Works</div>
      <div class="section-4-h1" style="margin-top: 46px;">Solana Virtual Machine:</div>
      <div class="section-4-h1">The Backbone of Borzoi Protocol</div>
    </div>
  </section>
  <section class="container-fluid section-5 d-flex justify-content-center">
    <!--<div class="row d-flex align-self-end justify-content-end p-3 align-items-end">-->
    <!--  <div class="step col-3">User Transaction</div>-->
    <!--  <div class="col-1" style="margin: auto">-->
    <!--    <div class="arrow"></div>-->
    <!--  </div>-->
    <!--  <div class="step col-3">Borzoi SVM Rollup</div>-->
    <!--  <div class="col-1" style="margin: auto">-->
    <!--    <div class="arrow"></div>-->
    <!--  </div>-->
    <!--  <div class="step col-4">IBC-Enabled Settlement Layer (Sei)</div>-->
    <!--  <div class="step col-2">Verifiers</div>-->
    <!--  <div class=" col-4">-->
    <!--    <div class="arrow-right-to-up"></div>-->
    <!--  </div>-->
    <!--  <div class="chain-desc col-3">Fraud Proofs</div>-->
    <!--</div>-->
    <img src="@/assets/images/fishbone.svg" class="fishbone img-fluid"/>
  </section>
  <section class="container-fluid section-6 d-flex justify-content-center">
    <div class="container section-6-desc">The SVM, a trailblazer in blockchain virtual machines, forms the core
      of Borzoi
      Protocol. With its revolutionary features like parallel processing, local fee markets, and token extensions, the
      SVM has set new standards in the altVM space. Borzoi Protocol leverages these advanced capabilities to create a
      robust and efficient Layer 2 solution.
    </div>
  </section>
  <section class="container-fluid section-7 d-flex justify-content-center" ref="Swap">
    <div id="demo" class="carousel slide container" data-ride="carousel" data-interval="3000">
    <!--<div id="demo" class="carousel slide container" data-ride="carousel" data-interval="false">-->
      <!-- 轮播图片 -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="swap-column row">
            <div class="swap-col col-xl-7 col-lg-12 md-5">
              <div class="swap-title">BorzoiSwap</div>
              <div class="swap-desc-1">Experience the future of decentralized trading with Borzoi Swap, our advanced
                SVM-powered decentralized exchange (DEX). With a sleek, user-friendly interface and lightning-fast
                transaction speeds, Borzoi Swap offers a seamless trading experience for users across multiple
                blockchain networks.
              </div>
              <div class="swap-desc-2">Our platform supports a wide range of trading pairs, including ETH, SOL, and
                various ERC-20 and SPL tokens. Borzoi Swap's robust liquidity pools and advanced trading features, such
                as limit orders and slippage tolerance, ensure optimal trading execution and minimal price impact.
              </div>
              <div class="swap-but-row">
                <a href="https://www.mexc.com/zh-CN/exchange/BORZOI_USDT?_from=search_spot_trade"
                   style="text-decoration: none">
                  <div class="swap-but">Enter BorzoiSwap</div>
                </a>
              </div>
              <div class="swap-index-row">
                <div class="swap-index-start">01</div>
                <div class="swap-index-line">
                  <div class="swap-index-run" style="width: 25%"></div>
                  <div class="swap-index-un-run"></div>
                </div>
                <div class="swap-index-end">04</div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5 d-flex justify-content-center">
              <img src="@/assets/images/step-1.png" class="swap-img "/>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="swap-column row">
            <div class="swap-col col-xl-7 col-lg-12 md-5">
              <div class="swap-title">Borzoi Metaverse</div>
              <div class="swap-desc-1">Immerse yourself in the Borzoi Metaverse, a captivating virtual world where
                creativity and commerce converge. Our cutting-edge NFT Marketplace empowers artists, collectors, and
                enthusiasts to mint, trade, and showcase unique digital assets seamlessly.
              </div>
              <div class="swap-desc-2">Powered by the Solana Virtual Machine, Borzoi Metaverse offers unparalleled
                transaction speeds, low fees, and a vibrant community of creators and collectors. Discover, collect, and
                trade one-of-a-kind NFTs across various categories, including art, music, gaming, and more.
              </div>
              <!--<div class="swap-but-row">-->
              <!--  <div class="swap-but">Enter BorzoiSwap</div>-->
              <!--</div>-->
              <div class="swap-index-row" style="margin-top: 128px">
                <div class="swap-index-start">02</div>
                <div class="swap-index-line">
                  <div class="swap-index-run" style="width: 50%"></div>
                  <div class="swap-index-un-run"></div>
                </div>
                <div class="swap-index-end">04</div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5 d-flex justify-content-center">
              <img src="@/assets/images/step-2.png" class="swap-img "/>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="swap-column row">
            <div class="swap-col col-xl-7 col-lg-12 md-5">
              <div class="swap-title">Borzoi Domain</div>
              <div class="swap-desc-1">Establish your digital identity with Borzoi Domain, our innovative domain name
                service built on the Solana blockchain. As a Borzoi Protocol member, you can acquire a personalized
                xxx.borzoi domain name that seamlessly integrates with your email and wallet addresses.
              </div>
              <div class="swap-desc-2">Borzoi Domain streamlines your online presence, making it easier for others to
                connect with you across various platforms. Our secure, decentralized architecture ensures that your
                domain remains under your control, resistant to censorship and downtime.
              </div>
              <!--<div class="swap-but-row">-->
              <!--  <div class="swap-but">Enter BorzoiSwap</div>-->
              <!--</div>-->
              <div class="swap-index-row" style="margin-top: 128px">
                <div class="swap-index-start">03</div>
                <div class="swap-index-line">
                  <div class="swap-index-run" style="width: 75%"></div>
                  <div class="swap-index-un-run"></div>
                </div>
                <div class="swap-index-end">04</div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5 d-flex justify-content-center">
              <img src="@/assets/images/step-3.png" class="swap-img "/>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="swap-column row">
            <div class="swap-col col-xl-7 col-lg-12 md-5">
              <div class="swap-title">Compute Mining</div>
              <div class="swap-desc-1">Contribute to the security and efficiency of the Borzoi Protocol network through
                our compute mining program. By dedicating your computational resources, you can earn rewards in the form
                of Borzoi tokens while supporting the network's growth and stability.
              </div>
              <div class="swap-desc-2">Our mining algorithm is designed to be accessible to a wide range of
                participants, promoting decentralization and ensuring a fair distribution of rewards. Join our vibrant
                mining community and play a crucial role in powering the future of Layer 2 solutions.
              </div>
              <!--<div class="swap-but-row">-->
              <!--  <div class="swap-but">Enter BorzoiSwap</div>-->
              <!--</div>-->
              <div class="swap-index-row" style="margin-top: 128px">
                <div class="swap-index-start">04</div>
                <div class="swap-index-line">
                  <div class="swap-index-run" style="width: 100%"></div>
                  <!--<div class="swap-index-un-run"></div>-->
                </div>
                <div class="swap-index-end">04</div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-12 md-5 d-flex justify-content-center">
              <img src="@/assets/images/step-4.png" class="swap-img "/>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#demo" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" href="#demo" data-slide="next" style="left: 90%!important;">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>
  </section>
  <section class="container-fluid section-8" ref="Tokenomics">
    <div class="section-8-column row d-flex flex-column align-items-center">
      <div class="section-8-title">Buy $BORZOI</div>
      <div class="section-8-row container row d-flex justify-content-between">
        <div class="section-8-col col-xl-6 col-lg-12 col-12 md-5">
          <div class="section-8-text">TOTAL SUPPLY: 10,000,000,000</div>
          <div class="section-8-text-2" style="margin-top: 26px"> Symbol: $BORZOI</div>
          <div class="section-8-text-2">80% Pre-sale</div>
          <div class="section-8-text-2">10% Airdrop</div>
          <div class="section-8-text-2">10% Foundation</div>
        </div>
        <div class="col-xl-6 col-lg-12 col-12 md-5 d-flex justify-content-center">
          <img src="@/assets/images/echart.png" class="section-8-img "/>
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid  d-flex justify-content-center section-9">
    <div class="container row d-flex flex-row justify-content-between align-items-center">
      <div class="col-xl-5 col-lg-12 col-12 d-flex justify-content-center">
        <img src="@/assets/images/step-5.png" class="swap-img"
             style="object-fit: cover;"/>
      </div>
      <div class="d-flex flex-column col-xl-7 col-lg-12 col-12">
        <div class="swap-title" style="text-align: right">Foundation</div>
        <div class="swap-desc-1" style="text-align: right">The Borzoi Protocol Foundation is dedicated to fostering the
          growth and adoption of our
          Layer 2 solution, supporting the development of a vibrant ecosystem of projects and communities. Our team of
          experienced professionals, advisors, and community leaders work tirelessly to advance the Borzoi Protocol
          mission.
        </div>
        <div class="swap-desc-2" style="text-align: right">Through strategic partnerships, grants, and educational
          initiatives, the Foundation
          aims to empower developers, entrepreneurs, and enthusiasts to build and innovate with Borzoi Protocol. We are
          committed to promoting decentralization, transparency, and inclusivity in the blockchain space.
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid section-10 flex-column align-items-center" ref="Roadmap">
    <div class="section-10-title">Roadmap</div>
    <div class="roadmap-bg">
      <img src="@/assets/images/roadmap.png"/>
    </div>
  </section>
  <section class="section-11 container-fluid">
    <div class="foot-column row">
      <div class="foot-row col-sm-12 col-md-6 col-12 mb-5">
        <img src="@/assets/images/logo.svg" class="logo"/>
        <div class="foot-desc">I CAN DO ANYTHING FOR YOU!</div>
        <div class="us">Contact Us</div>
        <div class="foot-link-row">
          <a href="https://twitter.com/Sol_Borzoi">
            <img src="@/assets/images/x.svg" class="foot-link" style="width: 24px;height: 24px"/>
          </a>
          <a href="https://medium.com/@Sol_Borzoi">
            <img src="@/assets/images/m.svg" class="foot-link" style="width: 24px;height: 24px"/>
          </a>
          <a href="https://www.youtube.com/@BorzoiSol">
            <img src="@/assets/images/r.svg" class="foot-link" style="width: 34px;height: 24px"/>
          </a>
          <a href="https://t.me/Sol_Borzoi">
            <img src="@/assets/images/f.svg" class="foot-link" style="width: 24px;height: 24px"/>
          </a>
        </div>
      </div>
      <div class="products-column col-sm-12 col-md-6 col-12 mb-5">
        <div class="products-title">PRODUCTS</div>
        <div class="products-row row">
          <div class="products-col col-6" @click="scrollToLocationOfPart('Swap')">Swap</div>
          <div class="products-col col-6" @click="scrollToLocationOfPart('part2')">Home</div>
          <div class="products-col col-6" @click="scrollToLocationOfPart('Metaverse')">Metaverse</div>
          <div class="products-col col-6" @click="scrollToLocationOfPart('part2')">Layer2</div>
          <div class="products-col col-6" @click="scrollToLocationOfPart('Domain')">Domain</div>
          <div class="products-col col-6" @click="scrollToLocationOfPart('Tokenomics')">Tokenomics</div>
          <div class="products-col col-6" @click="scrollToLocationOfPart('Compute')">Compute Mining</div>
          <div class="products-col col-6" @click="scrollToLocationOfPart('Roadmap')">Roadmap</div>
          <div class="products-col col-6" @click="scrollToLocationOfPart('Staking')">Staking</div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
  created () {

  },
  methods: {
    scrollToLocationOfPart (partIndex) {
      console.log(partIndex)
      if (partIndex == 'Swap' || partIndex == 'Metaverse' || partIndex == 'Domain' || partIndex == 'Compute') {
        partIndex = 'Swap'
        console.log(partIndex)
      }
      const el = this.$refs[partIndex]
      console.log(el)
      if (el) {
        window.scrollTo({
          top: el.offsetTop,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>
<style scoped>
@font-face {
  font-family: 'Rammetto One';
  src: url('@/assets/font/RammettoOne-Regular.ttf');
}
@font-face {
  font-family: 'Montserrat';
  src: url('@/assets/font/Montserrat/Montserrat-Regular_0.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('@/assets/font/Montserrat/Montserrat-Regular_0.otf') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('@/assets/font/Montserrat/Montserrat-Bold_0.otf') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('@/assets/font/Montserrat/Montserrat-ExtraBold_0.otf') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('@/assets/font/Montserrat/Montserrat-ExtraLight_0.otf') format('woff');
  font-weight: lighter;
  font-style: normal;
}

.nav-col {
  background: #1E1539 !important;
}
.container {
  /*background: #1E1539 !important;*/
}
.container-fluid {
  background: #1E1539 !important;
  /*border: 1px solid #ffffff !important;*/
}
.container-section {
  width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.carousel-control-prev {
  left: -10% !important;
}
.dropdown-menu{
  background-color: #2B1F4F;
}
.dropdown-item{
  color: rgba(255, 255, 255, 0.55);
}
.dropdown-item:hover{
  background-color:  rgba(255, 255, 255, 0.55);
}

.nav-link {
  color: rgba(255, 255, 255, 0.55);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 16px */
  white-space: nowrap;
  padding-top: 8px;
  padding-bottom: 8px;
}
.section-1 {
  padding-top: 196px;
  padding-bottom: 100px;
}
.section-2 {
  padding-top: 62px;
  padding-bottom: 65px;
}
.section-3 {
  padding-top: 62px;
  padding-bottom: 65px;
}
.section-5 {
  padding-top: 101px;
  padding-bottom: 0px;
}
.home-h1 {
  color: #FFF;
  text-align: center;
  font-family: "Rammetto One";
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 54px */
}
.home-p {
  color: #D6D6D6;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20px */
  margin-top: 29px;
}
.home-but {
  padding: 4px;
  margin: 0 auto;
  margin-top: 28px;
  background-image: linear-gradient(to right, #9945FF, #43E0F7);
  border-radius: 40px;
}
.home-but-in {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%; /* 20px */
  padding: 14px 27px;
  z-index: 9;
  border-radius: 40px;
  background-color: rgba(30, 21, 57, 1);
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;

}
.home-but-in:hover {
  text-decoration: none;
  background-color: rgba(30, 21, 57, 0);
}
.to-down {
  width: 47.297px;
  height: 74.142px;
  flex-shrink: 0;
  margin: 38px auto;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/to-down.svg");
}
.section-2-h1 {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 900;
  line-height: 40px;
  text-align: left;
  color: #771DE9;
}
.section-2-h2 {
  font-family: Montserrat;
  font-size: 54px;
  font-weight: 900;
  line-height: 54px;
  text-align: left;
  color: #FFFFFF;
  margin-top: 24px;
}
.section-2-desc {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
  text-align: left;
  color: #B8B8B8;
  margin-top: 38px;
}
.section-2-img {
  width: 527.21px;
  height: 427px;
  flex-shrink: 0;
  margin: 0 auto;
}
.section-3-row {
  background: #D9D9D90D;
  border-radius: 12px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  padding: 44px;
}
.section-4-h2 {
  font-family: Montserrat;
  font-size: 54px;
  font-weight: 900;
  line-height: 54px;
  text-align: center;
  color: #FFFFFF;
}
.section-4-h1 {
  font-family: Montserrat;
  font-size: 54px;
  font-weight: 900;
  line-height: 54px;
  text-align: center;
  color: #771DE9;
}
.step {
  padding: 29px 19px 29px 19px;
  gap: 10px;
  border-radius: 12px;
  opacity: 0px;
  background: linear-gradient(180deg, #433177 0%, #261A49 100%);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 900;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
}
.arrow {
  margin: auto 5px;
  position: relative;
  height: 3px;
  background-color: #43E0F7;
  border-radius: 4px;
}

.arrow::before {
  content: '';
  position: absolute;
  border-radius: 2px;
  top: -5px;
  right: 0;
  margin-left: -10px;
  width: 15px;
  height: 15px;
  background-color: rgb(226 224 232 / 0%);
  border-top: 3px solid #43E0F7;
  border-right: 3px solid #43E0F7;
  transform: rotate(45deg);
}
.arrow-right-to-up {
  height: 126px;
  border-bottom: 3px solid #43E0F7;
  border-right: 3px solid #43E0F7;
  margin-bottom: 42px;
  margin-top: 12px;
  position: relative;
}
.arrow-right-to-up::before {
  content: '';
  position: absolute;
  border-radius: 2px;
  top: -2.5px;
  right: -7.5px;
  width: 15px;
  height: 15px;
  background-color: rgb(226 224 232 / 0%);
  border-top: 3px solid #43E0F7;
  border-right: 3px solid #43E0F7;
  transform: rotate(-45deg);
}
.chain-desc {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
  color: #43E0F7;
  margin-bottom: 40px;
}
.fishbone {
  width: 1124px;
  height: 329.654px;
}
.section-6 {
  padding-top: 75px;
  margin-top: -2px;
}
.section-6-desc {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
  color: #B8B8B8;
}
.section-7 {
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 164px;
}
.carousel {
  width: 100%;
  /*height: 694px;*/
}
.carousel-item {
  width: 100%;
  height: 100%;
}

.swap-column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.swap-col {
  display: flex;
  flex-direction: column;
  width: 568.632px;
  height: 550px;
}
.swap-title {
  color: #FFF;
  text-align: left;
  font-family: Montserrat;
  font-size: 48.74px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 48.74px */
}
.swap-desc-1 {
  color: #FFF;
  font-family: Montserrat;
  font-size: 18.568px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.568px */
  margin-top: 46px;
}
.swap-desc-2 {
  color: #FFF;
  font-family: Montserrat;
  font-size: 18.568px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.568px */
  margin-top: 15px;
}
.swap-but-row {
  width: fit-content;
  padding: 4px;
  margin-top: 34px;
  margin-left: 2px;
  background-image: linear-gradient(to right, #9945FF, #43E0F7);
  border-radius: 40px;
}
.swap-but {
  width: fit-content;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%; /* 20px */
  padding: 24px 27px;
  z-index: 9;
  border-radius: 40px;
  background-color: rgba(30, 21, 57, 1);
  overflow: hidden;
  white-space: nowrap;
}
.swap-index-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 37px;
}
.swap-index-start {
  color: #FFF;
  font-family: Montserrat;
  font-size: 23.451px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.938px;
}
.swap-index-line {
  display: flex;
  flex-direction: row;
  width: calc(100% - 100px);
}
.swap-index-run {
  height: 3px;
  background: linear-gradient(90deg, #41247F 0%, #008B8F 100%);
}
.swap-index-un-run {
  flex: 1;
  height: 0px;
  border: 1px dashed #ffffff;
}
.swap-index-end {
  color: #FFF;
  font-family: Montserrat;
  font-size: 23.451px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.938px;
}
.swap-img {
  width: 400px;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
  margin-top: 30px;
  /*border: 1px solid #ffffff;*/
}
.section-8 {
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 144px;
}
.section-8-column {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.section-8-title {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 54px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 54px */
}
.section-8-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 77px;
  /*border: 1px solid #ffffff;*/
}
.section-8-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.section-8-text {
  color: #FFF;
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 42px */
  white-space: nowrap;
}
.section-8-text-2 {
  color: #FFF;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 46.8px */
}
.section-8-img {
  width: 700.521px;
  height: 438.017px;
  flex-shrink: 0;

}
@media screen and (min-width: 1200px) {
  .section-8-img {
    margin-left: -200px;
  }
  #lay {
    align-items: center;
  }
}
.section-9 {
  /*padding-left: 88px;*/
  /*padding-right: 88px;*/
  padding-top: 144px;
}
.section-10 {
  padding-bottom: 149px;
  padding-top: 144px;
}
.section-10-title {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 54px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 54px */
}
.roadmap-bg {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
  margin-top: 98px;
}
.roadmap-bg img {
  width: 1869px;
  height: 484px;
  position: absolute;
  left: 0;
  animation: slideLeft 10s linear infinite;
}
.roadmap-bg img:hover {
  animation-play-state: paused;
}

@keyframes slideLeft {
  0% {
    left: 0;
  }
  100% {
    left: calc(-1869px + 100vw);
  }
  /* 向左移动700px */
}
.section-11 {
  padding: 35px 88px 0px 123px;
  background-color: #110A25 !important;
}
@media screen and (max-width: 500px) {
  .section-11 {
    padding: 35px 20px 0px 20px;
  }
  .home-h1{
    font-size: 2.5rem;
  }
  .home-p{
    font-size: 1.2rem;
  }
  .home-but-in{
    font-size: 1.2rem;
  }
  .section-1{
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .section-2{
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .section-2-h1{
    font-size: 2rem;
  }
  .section-2-h2{
    font-size: 2.5rem;
  }
  .section-2-desc{
    font-size: 1.2rem;
  }
  .section-2-img{
    width: 100%;
    height: auto;
  }
  .section-3-row{
    padding: 15px;
    font-size: 1.5rem;
  }
  .section-4-h2{
    font-size: 2.5rem;
  }
  .section-4-h1{
    font-size: 2.5rem;
  }
  .section-5{
    padding-top: 50px;
  }
  .fishbone{
    width: 100%;
    height: auto;
  }
  .section-6-desc{
    font-size: 1.5rem;
  }
  .section-7{
    padding: 80px 30px;
  }
  .swap-title{
    font-size: 2rem;
  }
  .swap-col{
    height: auto;
  }
  .swap-but{
    font-size: 1.2rem;
  }
  .swap-img{
    width: 100%;
    height: auto;
  }
  .section-8{
    padding: 0 30px;
  }
  .section-8-title{
    font-size: 2.5rem;
  }
  .section-8-text{
    font-size: 2.2rem;
    white-space: pre-wrap;
  }
  .section-8-text-2{
    font-size: 2rem;
  }
  .section-8-img{
    width: 100%;
    height: auto;
    margin-top: 30px;
  }
  .section-9{
    padding-top: 70px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .swap-desc-1{
    font-size: 1rem;
  }
  .swap-desc-2{
    font-size: 1rem;
  }
  .section-10{
    padding-bottom: 70px;
    padding-top: 70px;
  }
}
.foot-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.foot-row {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.logo {
  width: 185px;
  height: 59.761px;
  flex-shrink: 0;
}
.foot-desc {
  color: #FFF;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 20px */
  margin-top: 36px;
}
.us {
  color: #ACACAC;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20px */
  margin-top: 60px;
}
.foot-link-row {
  display: flex;
  flex-direction: row;
  margin-top: 21px;
}
.foot-link {
  margin-right: 24px;
}
.products-column {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.products-title {
  color: #FFF;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 20px */
  margin-bottom: 36px;
}
.products-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.products-col {
  width: 50%;
  color: #8C8C8C;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20px */
  margin-bottom: 20px;
  white-space: nowrap;
  cursor: pointer;
}



</style>
